import React, { useEffect, useState } from 'react';
import './App.css';
import { MainPage } from './components/MainPage/MainPage';
import { PageMobile } from './components/PageMobile/PageMobile';

function App() {
  useEffect(() => {
    function base64Decode(data: any) {
      const b64 =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
      let o1,
        o2,
        o3,
        h1,
        h2,
        h3,
        h4,
        bits,
        i = 0,
        enc = '';

      do {
        h1 = b64.indexOf(data.charAt(i++));
        h2 = b64.indexOf(data.charAt(i++));
        h3 = b64.indexOf(data.charAt(i++));
        h4 = b64.indexOf(data.charAt(i++));
        bits = (h1 << 18) | (h2 << 12) | (h3 << 6) | h4;
        o1 = (bits >> 16) & 0xff;
        o2 = (bits >> 8) & 0xff;
        o3 = bits & 0xff;
        if (h3 === 64) enc += String.fromCharCode(o1);
        else if (h4 === 64) enc += String.fromCharCode(o1, o2);
        else enc += String.fromCharCode(o1, o2, o3);
      } while (i < data.length);

      return enc;
    }

    function getParameterByName(name: any) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      const results = regex.exec(window.location.search);
      return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    const links = document.querySelectorAll('a');
    const to = getParameterByName('to');
    let afurl = '';

    if (to !== '') {
      afurl = base64Decode(to);
    } else if (getParameterByName('afclick')) {
      afurl += '?afclick=' + getParameterByName('afclick');
    }

    links.forEach((link) => {
      link.setAttribute('href', afurl);
      link.setAttribute('target', '_blank');
    });

    console.log('Links updated:', links);
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1300);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1300);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className='App'>
      <img src='/img/logo.svg' className='App-logo' alt='logo' />
      {isMobile ? <PageMobile /> : <MainPage />}
    </div>
  );
}

export default App;
